import { useEffect,useRef,useState } from "react"
import { useParams } from "react-router-dom"
import {url,url2} from '../Componentes/Const'
import swal from 'sweetalert2'
export function PersonaAdd(){
    const ref_nombre = useRef()
    const ref_documento = useRef()
    const ref_nivel_id = useRef()
    const ref_cargo_id = useRef()
    const ref_seccion = useRef()
    const ref_basico = useRef()
    // const ref_cuenta = useRef()
    // const ref_banco = useRef()

    const [niveles, setNiveles] = useState([])
    const [cargos, setCargos] = useState([])

    useEffect(()=>{
        fetch(url2 +"/niveles")
        .then(response => response.json())
        .then(data =>{ console.log(data)
            setNiveles(data)
        })

        fetch(url2 +"/cargos")
        .then(response => response.json())
        .then(data =>{ console.log(data)
            setCargos(data)
        })


    },[])

    const handleSubmit =()=>{
        console.log("Guardando")
        const item = {
            "nombre" : ref_nombre.current.value,
            "documento" : ref_documento.current.value,
            "nivel_id" : ref_nivel_id.current.value,
            "cargo_id" : ref_cargo_id.current.value,
            "seccion" : ref_seccion.current.value,
            "basico" : ref_basico.current.value ,
            // "cuenta" : ref_cuenta.current.value ,
            // "banco" : ref_banco.current.value
        }
        console.log(item)
        const token = localStorage.getItem('token')
        const options = {
            method : "POST",
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : "Bearer "+token
            },
            body : JSON.stringify(item)
        }

        fetch(url + "/personas/PersonaAdd.php",options)
        .then(response => response.json())
        .then(data => { console.log(data)
            if(data.response == "ok"){
                //alert(data.msj)
                swal.fire(data.msj)
                setBoton(true)
            }
                
            else
                //alert(data.msj)
                swal.fire(data.msj)
        })
    }
    const [boton, setBoton] = useState(false);
    return <>
        <h2>Agregar una Persona</h2>
        <form>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Nombre
                </label>
                <input type="text" className="form-control" ref={ ref_nombre } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Documento
                </label>
                <input type="text" className="form-control" ref={ ref_documento } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Nivel
                </label>
                <select className="form-select" ref={ ref_nivel_id }>
                    
                    { niveles.map(dato=>(
                            <option value={dato.id }>
                                { dato.descripcion } 
                            </option>
                        )) }
                </select>
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Cargo
                </label>
                <select className="form-select" ref={ ref_cargo_id }>
                    
                    { cargos.map(dato=>(
                            <option value={dato.id }>
                                { dato.descripcion } 
                            </option>
                        )) }
                </select>
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Seccion
                </label>
                <select className="form-select" ref={ ref_seccion}>
                    <option value="" >Operativa</option> 
                    {/* { ides.categorias.map(dato=>(
                            <option value={dato.id }>
                                { dato.descripcion } 
                            </option>
                        )) } */}
                </select>
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Basico
                </label>
                <input type="text" className="form-control" ref={ ref_basico } />
            </div>
            {/* <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Cuenta
                </label>
                <input type="text" className="form-control" ref={ ref_cuenta }  />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Banco
                </label>
                <input type="text" className="form-control" ref={ ref_banco }  />
            </div> */}
            <div class="form-group my-4">
                <button type="button" className="btn btn-primary" disabled={boton} onClick={ handleSubmit }>
                    Guardar
                </button>
			</div>

        </form>
    </>
}