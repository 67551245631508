import React, {useState, useEffect} from 'react'
import {url2} from '../Componentes/Const'

import {Link} from 'react-router-dom'
export function NivelesList(){
    const [items, setItems] = useState([])
    
    function cargar(){
        const requestOptions = {
            method: "GET",
            header :{
                "Content-Type" : "application/json"
            }
        }
        fetch(url2 +"/niveles", requestOptions)
        .then(response => response.json())
        .then(data => { console.log(data)
            setItems(data)
        })
        .catch(error=> console.log(error))

    }
    useEffect(()=>{ 
        cargar()
    },[])
    

    return <>
        <div class="text-center" >
            <Link to="/tablero/categoriaCreate" className="btn btn-success" >Nuevo</Link>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th>Nivel</th>
                    <th>Opciones</th>
                </tr>
            </thead>
            <tbody>
                {
                    items.map(dato=>(
                        <tr><td>{ dato.descripcion }</td><td><Link to={`/tablero/nivelEdit/${dato._id}`} className="btn btn-secondary">Editar</Link></td></tr>
                    ))
                }
            </tbody>
        </table>
    </>

}