import { useEffect,useRef,useState } from "react"
import { useParams } from "react-router-dom"
import {url,url2} from '../Componentes/Const'
import swal from 'sweetalert2'
export function ConceptosEdit(){
    const ref_descripcion = useRef()
    const ref_nivel = useRef()
    const ref_tipo = useRef()
    const ref_categoria = useRef()
    const ref_precio = useRef()
    const ref_operacion = useRef()
    const ref_editable = useRef()
    const ref_estado = useRef()
    const ref_color = useRef()

    const {id} = useParams()
    //const [items,setItems] = useState([])
    const [ides, setIdes] = useState([])
    const [niveles, setNiveles] = useState([])
    const [tipos, setTipos] = useState([])
    const [categorias, setCategorias] = useState([])

    const [us_nivel, setUs_nivel] = useState([])
    const [us_tipo, setUs_tipo] = useState([])
    const [us_operacion, setUs_operacion] = useState([])
    const [us_categoria, setUs_categoria] = useState([])
    const [us_editable, setUs_editable] = useState([])
    const [us_estado, setUs_estado] = useState([])
    const [us_color, setUs_color] = useState([])
    console.log(id)
    useEffect(()=>{
        // if(historico)
        //     url = url +"/concepto/ConceptoHistorico.php?id=" + id + ""
        // else
        const token = localStorage.getItem('token')
        const options = {
            method : "GET",
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : "Bearer "+token
            }
        }
        fetch(url +"/metodos/get.php?tabla=conceptos&id=" + id, options)
        .then(response => response.json())
        .then(data =>{ console.log(data)
            ref_descripcion.current.value = data.descripcion
            setUs_nivel(data.nivel_id)
            setUs_tipo(data.tipo_id)
            setUs_categoria(data.categoria_id)
            //categoria_id = data.categoria_id
            ref_precio.current.value = data.precio
            //ref_operacion.current.value = data.operacion
            setUs_operacion(data.operacion)
            // ref_posicion.current.value = data.posicion
            setUs_editable(data.editable)
            setUs_estado(data.estado)
            setUs_color(data.color)
        })

        fetch(url2 +"/niveles")
        .then(response => response.json())
        .then(data =>{ console.log(data)
            setNiveles(data)
        })

        fetch(url2 +"/tipos")
        .then(response => response.json())
        .then(data =>{ console.log(data)
            setTipos(data)
        })

        fetch(url2 +"/categorias")
        .then(response => response.json())
        .then(data =>{ console.log(data)
            setCategorias(data)
        })

        // fetch(url +"/metodos/ides.php")
        // .then(response => response.json())
        // .then(data =>{ console.log(data)
        //     setIdes(data)
        // })

    },[])

    const handleSubmit =()=>{
        console.log("Editando")
        const user_id = localStorage.getItem("user_id")
        const item = {
            "id" : id,
            "descripcion" : ref_descripcion.current.value,
            "nivel_id" : ref_nivel.current.value,
            "tipo_id" : ref_tipo.current.value,
            "categoria_id" : ref_categoria.current.value,
            "precio" : ref_precio.current.value ,
            "operacion" : ref_operacion.current.value ,
            "editable" : ref_editable.current.value, 
            "estado" : ref_estado.current.value, 
            "color" : ref_color.current.value,
            "user_id" : user_id,
        }
        console.log(item)
        const token = localStorage.getItem('token')
        const options = {
            method : "PUT",
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : "Bearer "+token
            },
            body : JSON.stringify(item)
        }

        fetch(url + "/concepto/ConceptoUpdate.php",options)
        .then(response => response.json())
        .then(data => { //console.log(data)
            if(data.response == "ok")
                swal.fire(data.msj)
            else
                swal.fire(data.msj)
        })
    }

    const handleCategoria = ()=>{
        setUs_categoria(ref_categoria.current.value)
    }
    const handleEditable = ()=>{
        setUs_editable(ref_editable.current.value)
    }

    const handleOperacion = () =>{
        setUs_operacion(ref_operacion.current.value)
    }
    const handleEstado = ()=>{
        setUs_estado(ref_estado.current.value)
    }
    
    const handleColor = ()=>{
        setUs_color(ref_color.current.value)
    }
    return <>
        <h2>Editar un Concepto</h2>
        <form>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Descripcion
                </label>
                <input type="text" className="form-control" ref={ ref_descripcion } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Nivel
                </label>
                <select className="form-select" ref={ref_nivel} value={us_nivel} disabled>
                    { niveles.map(dato=>(
                        <option value={dato.id }>
                            { dato.descripcion } 
                        </option>
                    )) }
                </select>
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Tipo
                </label>
                <select className="form-select" ref={ref_tipo} value={us_tipo}>
                    { tipos.map(dato=>(
                            <option value={dato.id }>
                                { dato.descripcion } 
                            </option>
                        )) }
                </select>
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Categoria
                </label>
                <select className="form-select" ref={ref_categoria} value={ us_categoria } onChange={ handleCategoria }>
                    { categorias.map(dato=>(
                            <option value={dato.id }>
                                { dato.descripcion } 
                            </option>
                        )) }
                </select>
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Precio
                </label>
                <input type="text" className="form-control" ref={ ref_precio } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Operacion
                </label>
                <select className="form-select" ref={ref_operacion} value={ us_operacion } onChange={handleOperacion}>
                        <option value="multiplica">multiplica</option>
                        <option value="suma">suma</option>
                </select>
                <input type="hidden" className="form-control"   />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Editable
                </label>
                <select className="form-select" ref={ref_editable} value={us_editable} onChange={handleEditable}>
                        <option value='1' >Activo</option>
                        <option value='0' >Inactivo</option>
                </select>
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Estado
                </label>
                <select className="form-select" ref={ref_estado} value={us_estado} onChange={handleEstado}>
                        <option value='1' >Activo</option>
                        <option value='0' >Inactivo</option>
                </select>
            </div>
            
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Color
                </label>
                <select className="form-select" ref={ref_color} value={us_color} onChange={handleColor}>
                        <option value='default' class="table-default" ><label></label>Ninguno</option>
                        <option value='primary' className="table-primary">Azul</option>
                        <option value='secondary' ><label className="table-secondary">Gris</label></option>
                        <option value='success'>Verde</option>
                        <option value='danger'>Rojo</option>
                        <option value='warning'>Naranja</option>
                        <option value='info'>Azul</option>
                        <option value='light'>Light</option>
                        <option value='dark'>Dark</option>
                        
                </select>
            </div>
            <div class="form-group my-4">
                <button type="button" className="btn btn-primary" onClick={ handleSubmit }>
                    Actualizar
                </button>
			</div>

        </form>
    </>
}