import React, { useEffect, useState } from 'react'
import { url } from '../Componentes/Const'
import swal from 'sweetalert2'
export function DatoInput(props) {
  const [editing, setEditing] = useState(false);
  const [text, setText] = useState(props.valor);
  const [textF, setTextF] = useState(props.valor)
  const [conceptoDes, setConceptoDes] = useState(props.info.concepto_des)

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })

  function formatear(valor){
    let peso = parseFloat(valor)
    if(peso > 999){
      peso = formatter.format(peso)
    }else{
      peso = peso
    }
    return peso
  }
  useEffect(()=>{ 
    setText(props.valor)
    setTextF(formatear(props.valor))
  },[])

  const handleLabelClick = () => {
    setEditing(true);
  }

  const handleInputChange = (event) => {
    setText(event.target.value);
    setTextF(formatear(event.target.value))
  }

  const handleInputBlur = () => {
    setEditing(false);
    //props.onUpdate(text);
    console.log("nuevotexto", text)
    update(text)
  }

  function update(valor){
    console.log("Editando")
    const user_id = localStorage.getItem("user_id")
        const item = {
            "persona_id" : props.info.persona_id,
            "nomina_id": props.info.nomina_id,
            "concepto_id" : props.info.concepto_id,
            "valor" : valor,
            "user_id" : user_id
        }
        console.log(item)
        const token = localStorage.getItem('token')
        const options = {
            method : "PUT",
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : "Bearer "+token
            },
            body : JSON.stringify(item)
        }

        //metodo para direfercar si actualiza Pconceptos o Pnomina
        let entidad = "";
        if(item.concepto_id==6 || item.concepto_id == 'b'){//6 es dias laborados o basico
          entidad = "PersonaNomina"
        }else{
          entidad = "PersonaConcepto"
        }


        fetch(url + "/nomina/"+entidad+".php",options)
        .then(response => response.json())
        .then(data => { console.log(data)
            if(data.response == "ok"){
              //alert("ok")
              //swal.fire("Dato con Exito")
              const Toast = swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 800,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', swal.stopTimer)
                  toast.addEventListener('mouseleave', swal.resumeTimer)
                }
              })
              
              Toast.fire({
                icon: 'success',
                title: 'Actualizado con Exito'
              })
            }else
              //alert(data.msj)
              swal.fire(data.msj)
        })

        
  }

  return (
    <div>
      {editing ? (
        <input
          type="text"
          value={text}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          class="form-control form-control"
          size="6"
        />
      ) : (
        //  conceptoDes == "ARL" ? (
        //   <label className="bg-primary" onClick={handleLabelClick}>{text}</label>
        // ) : (
          <p onClick={handleLabelClick}>{textF}</p>
        //) 
        
      )}
    </div>
  );
}

export default DatoInput;