import { useRef, useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import swal from 'sweetalert'
import logo1 from '../logo.png'
import {url2} from '../Componentes/Const'
const { REACT_APP_API_URL2 } = process.env;
export function Login(){
	const correoRef = useRef()
	const contrasenaRef = useRef()
	const [mensaje,setMensaje] = useState()
	const navigate = useNavigate()

	const imagen = 'logo.png'
	const handleChange = (ev)=>{ console.log(ev.target.value) }
	const handleSubmit = (ev) =>{
		ev.preventDefault()
		
	} 
	const handleClick =()=>{
		console.log("validado login...")
		// let correo = document.frm.correo.value 
		let correo = correoRef.current.value
		let contrasena = contrasenaRef.current.value
		const usuario = { 
			'email' : correo,
			'password' : contrasena
		}
		
		const options = {
			method : "POST",
			body : JSON.stringify(usuario),
			headers : {
				'Content-Type' : 'application/json'
			}
		}
		console.log(usuario)
		fetch(url2+`/v1/auth/login`,options)
		.then(response => response.json())
		.then(data => { console.log(data)
			if(data.access_token){
				navigate("/tablero")
				localStorage.setItem("token",data.access_token)
			}else{
				// swal.fire({
				// 			title: "Validacion",
				// 			text: "Datos erroneos",
				// 			icon: "warning"
				// 		})
				alert("Problema para accesar a la plataforma!")
			}
		})
		.catch(err => console.log(err))
		// if(correo ==="correo"){
		// 	setMensaje("Datos Correctos")
		// 	navigate("/tablero")
		// }else{
		// 	setMensaje("Datos Incorrectos")
		// 	//alert("Datos incorrectos")
		// 	swal.fire({
		// 		title: "Validacion",
		// 		text: "Datos erroneos",
		// 		icon: "warning"
		// 	})
		// }
		//....enviar al serv
	}

	// const [ items, setItems] = useState([])
    // function listar(){
    //     console.log("Listando Peliculas...")
    //     fetch("http://localhost:3000/api/items")
    //     .then(response => response.json())
    //     .then(data =>{ console.log(data)
    //             setItems(data)
    //     })
    // }
	// useEffect(()=>{ listar() },[])
    return <>
        <div className='container'>
			<div className='row my-5'>
				<div className="container-fluid">
					<div className="row g-3 py-5">
						<div className="col-lg-4  col-xs-12 offset-lg-4">
							{mensaje }

							<center>
								<img src={logo1} alt="logo" width={200} height={100} />
							</center>
							
							<form name="frm" class="py-5" onSubmit={handleSubmit}>
								<div className="form-group"> 
									<label htmlFor="exampleInputEmail1">
										Correo Electrónico
									</label>
									<input type="email" name="correo" ref={ correoRef }className="form-control" id="exampleInputEmail1" onChange={ handleChange }/>
								</div>
								<div className="form-group">
									<label htmlFor="exampleInputPassword1">
										Contraseña
									</label>
									<input type="password" ref={ contrasenaRef} className="form-control" id="exampleInputPassword1" />
								</div>
								<div className="form-group my-4">
									<button type="button" className="btn btn-primary" onClick={ handleClick } >
										Acceder
									</button>
								</div>				
							</form>
							{mensaje}
							{/* {items.map(dato=>( <div> { dato.titulo }</div>))} */}
						</div>
					</div>
				</div>
			</div>
	</div>
</>
}