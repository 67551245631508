import { useEffect,useRef,useState } from "react"
import { useParams } from "react-router-dom"
import {url,url2} from '../Componentes/Const'
import swal from 'sweetalert2'
export function UsuarioEdit(){
    const ref_nombre = useRef()
    const ref_correo = useRef()
    const ref_contrasena = useRef()
    const ref_perfil = useRef()
    const ref_estado = useRef()
  
    const {id} = useParams()

    const [us_estado, setUs_estado] = useState([])
    const [us_perfil, setUs_perfil] = useState([])
    const [us_disabled, setUs_disabled] = useState(false)
    console.log(id)
    useEffect(()=>{
        const token = localStorage.getItem("token")
        const requestOptions = {
            method: "GET",
            headers :{
                'Content-Type' : 'application/json',
                'Authorization' : 'Bearer '+token
            }
        }
        fetch(url +"/metodos/get.php?tabla=users&id=" + id, requestOptions)
        .then(response => response.json())
        .then(data =>{ console.log(data)
            ref_nombre.current.value = data.name
            ref_correo.current.value = data.email
            setUs_perfil(data.profile)
            setUs_estado(data.estado)
            if(data.profile != 1 && data.profile !=2)
                setUs_disabled(true)
        })
    },[])

    const handleSubmit =()=>{
        console.log("Editando")
        const item = {
            "id" : id,
            "nombre" : ref_nombre.current.value,
            "contrasena" : ref_contrasena.current.value,
            "correo" : ref_correo.current.value,
            "perfil" : ref_perfil.current.value,
            "estado" : ref_estado.current.value 
        }
        console.log(item)
        const token = localStorage.getItem('token')
        const options = {
            method : "PUT",
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : "Bearer "+token
            },
            body : JSON.stringify(item)
        }
        fetch(url + "/usuarios/UsuarioUpdate.php",options)
        .then(response => response.json())
        .then(data => { console.log(data)
            if(data.response == "ok")
                swal.fire(data.msj)
            else
                swal.fire(data.msj)
        })
    }

    const handleEstado = ()=>{
        setUs_estado(ref_estado.current.value)
    }

    const handlePerfil= ()=>{
        setUs_estado(ref_perfil.current.value)
    }
    return <>
        <h2>Editar un Usuario</h2>
        <form>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Nombre
                </label>
                <input type="text" className="form-control" ref={ ref_nombre } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Correo
                </label>
                <input type="text" className="form-control" ref={ ref_correo } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Contraseña
                </label>
                <input type="password" className="form-control" ref={ ref_contrasena } placeholder="Diligenciar en caso de Nueva contraseña"/>
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Perfil
                </label>
                <select className="form-select" ref={ref_perfil} value={us_perfil} onChange={handlePerfil} disabled={us_disabled}>
                        <option value='1' >Administrador</option>
                        <option value='2' >Contable</option>
                        <option value='3' >Operador</option>
                        <option value='4' >Auxiliar</option>
                        
                </select>
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Estado
                </label>
                <select className="form-select" ref={ref_estado} value={us_estado} onChange={handleEstado}>
                        <option value='1' >Activo</option>
                        <option value='0' >Inactivo</option>
                </select>
            </div>
            <div class="form-group my-4">
                <button type="button" className="btn btn-primary" onClick={ handleSubmit }>
                    Actualizar
                </button>
			</div>

        </form>
    </>
}