import { useRef } from 'react';
import html2canvas from 'html2canvas'
export function Canva(){
    const exportRef = useRef();
    const exportAsImage = async (el, imageFileName) => {
        const canvas = await html2canvas(el);
        const image = canvas.toDataURL("image/png", 1.0);
        downloadImage(image, imageFileName);
    };
    const downloadImage = (blob, fileName) => {
        const fakeLink = window.document.createElement("a");
        fakeLink.style = "display:none;";
        fakeLink.download = fileName;
        
        fakeLink.href = blob;
        
        document.body.appendChild(fakeLink);
        fakeLink.click();
        document.body.removeChild(fakeLink);
        
        fakeLink.remove();
        };

    

    return(<>
        <div ref={exportRef}>
        <p>Quis blandit turpis cursus in hac habitasse. Commodo quis imperdiet
massa tincidunt nunc pulvinar sapien et ligula. Sit amet dictum sit
amet justo donec. Cursus mattis molestie a iaculis. Vel pretium
lectus quam id leo in vitae. Quam nulla porttitor massa id neque
aliquam vestibulum morbi blandit.</p>
        </div>
        <button onClick={() => exportAsImage(exportRef.current, "test")}>
        Capture Image
        </button>
    </>)
}