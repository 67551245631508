import React, { useState } from "react";

function Table({ data }) {
  const [columns, setColumns] = useState(Object.keys(data[0]));

  const toggleColumn = (column) => {
    if (columns.includes(column)) {
      setColumns(columns.filter((c) => c !== column));
    } else {
      setColumns([...columns, column]);
    }
  };

  return (
    <table>
      <thead>
        <tr>
          {Object.keys(data[0]).map((column) => (
            <th key={column}>
              <label>
                <input
                  type="checkbox"
                  checked={columns.includes(column)}
                  onChange={() => toggleColumn(column)}
                />
                {column}
              </label>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            {columns.map((column) => (
              <td key={column}>{row[column]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default Table;