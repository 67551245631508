import { useEffect,useRef,useState } from "react"
import { useParams } from "react-router-dom"
import {url,url2} from '../Componentes/Const'
import swal from 'sweetalert2'
export function ConceptoHistoricoEdit(){
    const ref_descripcion = useRef()
    const ref_precio = useRef()
    const {id} = useParams()

    console.log(id)
    useEffect(()=>{
        const token = localStorage.getItem('token')
        const options = {
            method : "GET",
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : "Bearer "+token
            }
        }
        console.log(url +"/concepto/ConceptoHistorico.php?id="+id,options)
        fetch(url+"/concepto/ConceptoHistorico.php?id="+id)
        .then(response => response.json())
        .then(data =>{ console.log(data)
            ref_descripcion.current.value = data.descripcion
            ref_precio.current.value = data.precio
        })
    },[])

    const handleSubmit =()=>{
        console.log("Actualizando")
        const user_id = localStorage.getItem("user_id")
        const item = {
            "id" : id,
            "precio" : ref_precio.current.value ,
            "user_id" : user_id,
        }
        console.log(item)
        const token = localStorage.getItem('token')
        const options = {
            method : "PUT",
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : "Bearer "+token
            },
            body : JSON.stringify(item)
        }

        fetch(url + "/concepto/ConceptoHistoricoUpdate.php",options)
        .then(response => response.json())
        .then(data => { //console.log(data)
            if(data.response == "ok")
                swal.fire(data.msj)
            else
                swal.fire(data.msj)
        })
    }

    return <>
        <h2>Editar un Concepto Historico</h2>
        <form>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Descripcion
                </label>
                <input type="text" className="form-control" ref={ ref_descripcion } disabled/>
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Precio
                </label>
                <input type="text" className="form-control" ref={ ref_precio } />
            </div>
            <div class="form-group my-4">
                <button type="button" className="btn btn-primary" onClick={ handleSubmit }>
                    Actualizar
                </button>
			</div>
        </form>
    </>
}