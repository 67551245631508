import { useEffect,useRef,useState } from "react"
import { useParams } from "react-router-dom"
import {url,url2} from '../Componentes/Const'
import swal from 'sweetalert2'
export function EmpresaEdit(){
    const ref_razon = useRef()
    const ref_representante = useRef()
    const ref_direccion = useRef()
    const ref_telefono = useRef()
    const ref_seccion = useRef()
   
    const ref_fs = useRef()
    const ref_minimo = useRef()
    const ref_salud = useRef()

    useEffect(()=>{
        const token = localStorage.getItem('token') 
        const options = {
            method : "GET",
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : "Bearer "+token
            }
        }
        fetch(url +"/empresa/EmpresaBuscar.php",options)
        .then(response => response.json())
        .then(data =>{ console.log("dta empresa",data)
            ref_razon.current.value = data.razon_social
            ref_representante.current.value = data.representante
            ref_direccion.current.value = data.direccion
            ref_telefono.current.value = data.telefono
        })

        fetch(url +"/empresa/OtrosBuscar.php",options)
        .then(response => response.json())
        .then(data =>{ console.log(data)
            ref_fs.current.value = data.fs.valor
            ref_minimo.current.value = data.salario_minimo.valor
            ref_salud.current.value = data.salud.valor
        })
    },[])

    const handleSubmit =()=>{
        console.log("Editando")
        const item = {
            "razon" : ref_razon.current.value,
            "representante" : ref_representante.current.value,
            "direccion" : ref_direccion.current.value,
            "telefono" : ref_telefono.current.value,
        }
        console.log(item)
        const token = localStorage.getItem('token')
        const options = {
            method : "PUT",
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : "Bearer "+token
            },
            body : JSON.stringify(item)
        }

        fetch(url + "/empresa/EmpresaUpdate.php",options)
        .then(response => response.json())
        .then(data => { console.log(data)
            if(data.response == "ok")
                swal.fire(data.msj)
            else
                swal.fire(data.msj)
        })
    }

    const handleSubmitO =()=>{
        console.log("Editando")
        const item = {
            "fs" : ref_fs.current.value,
            "minimo" : ref_minimo.current.value,
            "salud" : ref_salud.current.value,
        }
        console.log(item)
        const token = localStorage.getItem('token')
        const options = {
            method : "PUT",
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : "Bearer "+token
            },
            body : JSON.stringify(item)
        }

        fetch(url + "/empresa/OtrosUpdate.php",options)
        .then(response => response.json())
        .then(data => { console.log(data)
            if(data.response == "ok")
                swal.fire(data.msj)
            else
                swal.fire(data.msj)
        })
    }

    return <>
        <h2>Datos Generales</h2>
        <form>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Razon Social
                </label>
                <input type="text" className="form-control" ref={ ref_razon } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Representante
                </label>
                <input type="text" className="form-control" ref={ ref_representante } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Direccion
                </label>
                <input type="text" className="form-control" ref={ ref_direccion } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Telefono
                </label>
                <input type="text" className="form-control" ref={ ref_telefono } />
            </div>
            <div class="form-group my-4">
                <button type="button" className="btn btn-primary" onClick={ handleSubmit }>
                    Actualizar
                </button>
			</div>

        </form>
        <hr></hr>
        <form>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Factor Salarial
                </label>
                <input type="text" className="form-control" ref={ ref_fs } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Salario minimo
                </label>
                <input type="text" className="form-control" ref={ ref_minimo } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    % Salud
                </label>
                <input type="text" className="form-control" ref={ ref_salud } />
            </div>
            <div class="form-group my-4">
                <button type="button" className="btn btn-primary" onClick={ handleSubmitO }>
                    Actualizar
                </button>
			</div>
        </form>
    </>
}