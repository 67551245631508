import React, {useState, useEffect} from 'react'
import {url2} from '../Componentes/Const'

import {Link} from 'react-router-dom'
export function CargosList(){
    const [items, setItems] = useState([])
    
    function cargar(){
        const token = localStorage.getItem('token') 
        const requestOptions = {
            method: "GET",
            header :{
                "Content-Type" : "application/json",
                "Authorization" : "Bearer "+token
            }
        }
        fetch(url2 +"/cargos", requestOptions)
        .then(response => response.json())
        .then(data => { console.log(data)
            setItems(data)
        })
        .catch(error=> console.log(error))

    }
    useEffect(()=>{ 
        cargar()
    },[])
    

    return <>
        <div class="text-center" >
            <Link to="/tablero/cargoCreate" className="btn btn-success" >Nuevo</Link>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th>Cargo</th>
                    <th>Opciones</th>
                </tr>
            </thead>
            <tbody>
                {
                    items.map(dato=>(
                        <tr>
                            <td>{ dato.descripcion }</td><td><Link to={`/tablero/cargoEdit/${dato.id}`} className="btn btn-secondary">Editar</Link></td>
                            { dato.estado == 1 ? (
                                <td><span className="badge rounded-pill bg-success">Activo</span></td>
                            ):(<td><span className="badge rounded-pill bg-danger">Inactivo</span></td>) }    
                        </tr>
                    ))
                }
            </tbody>
        </table>
    </>

}