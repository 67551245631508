import { useEffect,useRef,useState } from "react"
import { useParams } from "react-router-dom"
import {Link} from 'react-router-dom'
import {url} from '../Componentes/Const'
import swal from 'sweetalert2'
export function CargoCreate(){
    const ref_descripcion = useRef()

    const handleSubmit =()=>{
        let errores = false

        if(ref_descripcion.current.value.length < 4){
            errores = true
            swal.fire("Debes llenar el dato Descripcion")
        }
        
        if(!errores){
            console.log("Guardando")
            const item = {
                "descripcion": ref_descripcion.current.value
            }
            console.log(item)
            const token = localStorage.getItem('token')
            const options = {
                method : "POST",
                headers : {
                    "Content-Type" : "application/json",
                    "Authorization" : "Bearer "+token
                },
                body : JSON.stringify(item)
            }

            fetch(url + "/cargos/CargoAdd.php",options)
            .then(response => response.json())
            .then(data => { console.log(data)
                if(data.response == "ok")
                    swal.fire(data.msj)
                else
                    swal.fire(data.msj)
            })
            
        } 
    }
    return <>
        <h2>Crear Cargo</h2>
        <form>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Descripcion
                </label>
                <input type="text" className="form-control" ref={ ref_descripcion } />
            </div>
            <div class="form-group my-4">
                <button type="button" className="btn btn-primary" onClick={ handleSubmit }>
                    Guardar
                </button>
			</div>
        </form>
    </>
}