import React, { useEffect, useState } from 'react';

export function DatoLabelB(props) {
  const [textF, setTextF] = useState(props.text);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })

  useEffect(()=>{ 

    let peso = parseFloat(props.text)
    if(peso > 999){
      peso = formatter.format(peso)
    }else{
      peso = peso
    }
    setTextF(peso)
  },[])

    return (
    <div>
        <label className=" bg-warning"><b>{textF}</b></label>
    </div>
  );
}

export default DatoLabelB;