import React from 'react';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import './App.css';
import { NominaList } from './Nominas/NominaList';
import { Tablero } from './Componentes/Tablero';
import { Principal } from './Componentes/Principal'
import { Login } from './Usuarios/Login'
import { Tabla2 } from './Tests/Tabla2';
import { MyComponent } from './Tests/MyComponent'
import { PrintDiv } from './Tests/PrintDiv'
import { Canva } from './Tests/Canva';
import { Toast} from './Tests/Toast'
function App() {
  return (<> 
    <Router>
        {/* <Menu/> */}
        <header class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
            <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">Nomina</a>
            <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-nav">
                <div class="nav-item text-nowrap">
                {/* <a class="nav-link px-3" href="#">Salir</a> */}
                </div>
            </div>
        </header>
        <div className='container-fluid'>
            <div className='row align-center'>
                {/* <div className='col m-5'> */}
                    <Routes>
                        {/* <Route path="/" element={ <ListaEventos/> }></Route> */}
                        {/* <Route path="/nomina" element={ <NominaList/> }></Route> */}
                        {/* <Route path="/" element={<Principal/>}></Route> */}
                        <Route path="/tablero/*" element={<Tablero/> }></Route>
                        <Route path="/" element={<Login/>}></Route>
                        <Route path="/test" element={<Canva/>}></Route>
                        <Route path="/login" element={<Login/>}></Route>
                    </Routes>
                {/* </div> */}
            </div>
        </div>

    </Router>
    </>);
}
export default App;