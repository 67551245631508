import { useEffect,useRef,useState } from "react"
import { useParams } from "react-router-dom"
import {url,url2} from '../Componentes/Const'
import swal from 'sweetalert2'
export function PersonaEdit(){
    const ref_nombre = useRef()
    const ref_documento = useRef()
    const ref_nivel_id = useRef()
    const ref_cargo_id = useRef()
    const ref_seccion = useRef()
    const ref_basico = useRef()
    //const ref_cuenta = useRef()
    //const ref_posicion = useRef()
    const ref_estado = useRef()

    const {id} = useParams()
    const [items,setItems] = useState([])
    const [niveles, setNiveles] = useState([])
    const [cargos, setCargos] = useState([])

    const [us_nivel, setUs_nivel] = useState([])
    const [us_cargo_id, setUs_cargo_id] = useState([])
    const [us_estado, setUs_estado] = useState([])
    console.log(id)
    useEffect(()=>{
        const token = localStorage.getItem('token') 
        const options = {
            method : "GET",
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : "Bearer "+token
            }
        }
        fetch(url +"/metodos/get.php?tabla=personas&id=" + id,options)
        .then(response => response.json())
        .then(data =>{ console.log(data)
            ref_nombre.current.value = data.nombre
            ref_documento.current.value = data.documento
            setUs_nivel( data.nivel_id)
            setUs_cargo_id(data.cargo_id)
            //ref_seccion.current.value = data.seccion
            ref_basico.current.value = data.basico
            //ref_cuenta.current.value = data.cuenta
            setUs_estado(data.estado)
        })

        fetch(url2 +"/niveles")
        .then(response => response.json())
        .then(data =>{ console.log(data)
            setNiveles(data)
        })

        fetch(url2 +"/cargos")
        .then(response => response.json())
        .then(data =>{ console.log(data)
            setCargos(data)
        })


    },[])

    const handleSubmit =()=>{
        console.log("Editando")
        const item = {
            "id" : id,
            "nombre" : ref_nombre.current.value,
            "documento" : ref_documento.current.value,
            "nivel_id" : ref_nivel_id.current.value,
            "cargo_id" : ref_cargo_id.current.value,
            "basico" : ref_basico.current.value ,
            //"cuenta" : ref_cuenta.current.value ,
            "estado" : ref_estado.current.value 
        }
        console.log(item)
        const token = localStorage.getItem('token')
        const options = {
            method : "PUT",
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : "Bearer "+token
            },
            body : JSON.stringify(item)
        }
        fetch(url + "/personas/PersonaUpdate.php",options)
        .then(response => response.json())
        .then(data => { console.log("data",data)
            if(data.response == "ok")
                swal.fire(data.msj)
            else
                swal.fire(data.msj)
        })
    }

    const handleNivel = ()=>{
        setUs_nivel(ref_nivel_id.current.value)
    }

    const handleCargo = ()=>{
        setUs_cargo_id(ref_cargo_id.current.value)
    }

    const handleEstado = ()=>{
        setUs_estado(ref_estado.current.value)
    }
    return <>
        <h2>Editar una Persona</h2>
        <form>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Nombre
                </label>
                <input type="text" className="form-control" ref={ ref_nombre } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Documento
                </label>
                <input type="text" className="form-control" ref={ ref_documento } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Nivel
                </label>
                <select className="form-select" ref={ ref_nivel_id } value={us_nivel} onChange={handleNivel}>
                    
                    { niveles.map(dato=>(
                            <option value={dato.id }>
                                { dato.descripcion } 
                            </option>
                        )) }
                </select>
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Cargo
                </label>
                <select className="form-select" ref={ ref_cargo_id } value={us_cargo_id}  onChange={handleCargo}>
                    
                    { cargos.map(dato=>(
                            <option value={dato.id }>
                                { dato.descripcion } 
                            </option>
                        )) }
                </select>
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Seccion
                </label>
                <select className="form-select">
                    <option value="" >Operativa</option> 
                    {/* { ides.categorias.map(dato=>(
                            <option value={dato.id }>
                                { dato.descripcion } 
                            </option>
                        )) } */}
                </select>
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Basico
                </label>
                <input type="text" className="form-control" ref={ ref_basico } />
            </div>
            {/* <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Cuenta
                </label>
                <input type="text" className="form-control" ref={ ref_cuenta }  />
            </div> */}
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Estado
                </label>
                <select className="form-select" ref={ref_estado} value={us_estado} onChange={handleEstado}>
                        <option value='1' >Activo</option>
                        <option value='0' >Inactivo</option>
                </select>
            </div>
            <div class="form-group my-4">
                <button type="button" className="btn btn-primary" onClick={ handleSubmit }>
                    Actualizar
                </button>
			</div>

        </form>
    </>
}