import { useEffect,useRef,useState } from "react"
import { useParams } from "react-router-dom"
import {Link} from 'react-router-dom'
import {url} from '../Componentes/Const'
import swal from 'sweetalert2'
export function NominaCreate(){
    const ref_fechaInicio = useRef()
    const ref_fechaFin = useRef()
    const ref_descripcion = useRef()

    const handleSubmit =()=>{
        let confirmado = false
        swal.fire({
            title: 'Realizaste los ajustes de empleados, salarios niveles y precios de conceptos?',
            showCancelButton: true,
            confirmButtonText: 'Si, Continuar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let errores = false

                if(ref_fechaInicio.current.value.length < 4){
                    errores = true
                    swal.fire("Debes llenar los datos fecha de Inicio")
                }
                if(ref_fechaFin.current.value.length < 4){
                    errores = true
                    swal.fire("Debes llenar los datos fecha de Fin")
                }
                if(ref_descripcion.current.value.length < 4){
                    errores = true
                    swal.fire("Debes llenar la descripción")
                }
                if(!errores){

                
                    console.log("Guardando")
                    const item = {
                        "fecha_inicio" : ref_fechaInicio.current.value,
                        "fecha_fin": ref_fechaFin.current.value,
                        "descripcion": ref_descripcion.current.value
                    }
                    console.log(item)
                    const token = localStorage.getItem('token')
                    const options = {
                        method : "POST",
                        headers : {
                            "Content-Type" : "application/json",
                            "Authorization" : "Bearer "+token
                        },
                        body : JSON.stringify(item)
                    }

                    fetch(url + "/nomina/NominaCreate.php",options)
                    .then(response => response.json())
                    .then(data => { console.log(data)
                        if(data.response == "ok")
                            swal.fire(data.msj)
                        else
                            swal.fire(data.msj)
                    })
                    
                }
            } else if (result.isDenied) {
                confirmado = false
            }
        })
        
    }
    return <>
        <h2>Crear Nomina</h2>
        <form>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Fecha Inicio
                </label>
                <input type="date" className="form-control" ref={ ref_fechaInicio } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Fecha Fin
                </label>
                <input type="date" className="form-control" ref={ ref_fechaFin } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Descripcion
                </label>
                <input type="text" className="form-control" ref={ ref_descripcion } />
            </div>
            <div class="form-group my-4">
                <button type="button" className="btn btn-primary" onClick={ handleSubmit }>
                    Guardar y Generar
                </button>
			</div>

        </form>
    </>
}