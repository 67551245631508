import React, {useState, useEffect, useRef} from 'react'
import {url, url2} from '../Componentes/Const'

import {Link} from 'react-router-dom'
export function PersonasList(){
    const ref_texto = useRef()
    const [items, setItems] = useState([])
    
    function cargar(){
        const requestOptions = {
            method: "GET",
            header :{
                "Content-Type" : "application/json"
            }
        }
        fetch(url2 +"/personas", requestOptions)
        .then(response => response.json())
        .then(data => { console.log(data)
            setItems(data)
        })
        .catch(error=> console.log(error))

    }
    useEffect(()=>{ 
        cargar()
    },[])
    
    function handlePersonaBuscar(){
        const requestOptions = {
            method: "GET",
            header :{
                "Content-Type" : "application/json"
            }
        }
        const texto = ref_texto.current.value
        fetch(url +"/personas/PersonaBuscar.php?texto="+texto, requestOptions)
        .then(response => response.json())
        .then(data => { console.log(data)
            setItems(data)
        })
        .catch(error=>{
            console.log(error)
            alert("error obteniendo datos")
        } )

    }
    return <>
        <div className="row" >
            <div className='col'>
            <Link to="/tablero/personaAdd" className="btn btn-success" >Nueva Persona</Link>
            </div>
            <div className='col'>
                <div className="row" >
                    <div className='col'>
                        <input class="form-control form-control-lg" ref={ref_texto} placeholder='Buscar...'></input>
                    </div>
                    <div className='col'>
                        <button type="button" class="btn btn-primary btn-lg" onClick={handlePersonaBuscar}>B</button>
                    </div>
                </div>
            </div>
            <div className='col'>
            <a href={`${url}/personas/PersonasListPDF.php?tipo=xls`} target="_blank" className="btn btn-outline-success btn-sm" >XLS</a>
            </div>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th>Empleado</th>
                    <th>Nivel</th>
                    <th>Basico</th>
                    <th>Opciones</th>
                    <th>Estado</th>

                </tr>
            </thead>
            <tbody>
                {
                    items.map(dato=>(
                        <tr>
                            <td>{ dato.nombre }</td>
                            <td>{ dato.nivel_id }</td>
                            <td>{ dato.basico }</td>
                            <td><Link to={`/tablero/personaEdit/${dato.id}`} className="btn btn-secondary">Editar</Link></td>
                            { dato.estado == 1 ? (
                                <td><span className="badge rounded-pill bg-success">Activo</span></td>
                            ):(<td><span className="badge rounded-pill bg-danger">Inactivo</span></td>) }
                        </tr>
                    ))
                }
            </tbody>
        </table>
    </>

}