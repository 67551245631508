import { useState,useEffect, useRef } from 'react'
import {url,url2} from '../Componentes/Const'

export function Informes(){
    const [empleados,setEmpleados] = useState([])
    const [empleado,setEmpleado] = useState([])
    const ref_empleado = useRef()
    function cargar(){
        const requestOptions = {
            method: "GET",
            header :{
                "Content-Type" : "application/json"
            }
        }
        fetch(url2 +"/personas", requestOptions)
        .then(response => response.json())
        .then(data => { console.log(data)
            setEmpleados(data)
        })
        .catch(error=> console.log(error))

    }
    useEffect(()=>{ 
        cargar()
    },[])

    const handleEmpleado = ()=>{
        setEmpleado(ref_empleado.current.value)
    }

    const handleCargar=()=>{
        const persona_id = empleado
        
        window.open(url + "/informes/Devengados.php?persona_id=" + empleado+"&tipo=xls","_blank","no_referrer")
    }


    return (<>
        <div className='container'>
            {/* <div className='row'>
                <div className='col-3'>
                    <a href={`${url}/nomina/NominaGeneralHoja.php`} target="_blank" className="btn btn-outline-info btn-sm" >Consolidado</a>
                </div>
            </div> */}
            <div className='row'>
                <div className='col-6'>
                <form>
                    <h2>Informe Devengados Empleados</h2>
                    <div class="form-group"> 
                    <label>Seleccione empleado:</label>
                    <select className="form-select" ref={ref_empleado} onChange={handleEmpleado}>
                        <option value="0" >Seleccione</option>
                        {
                            empleados.map(dato=>(
                                <option value={dato.id} >{dato.nombre} - Nv. {dato.nivel_id}</option>
                            ))
                        }        
                    </select>
                    </div>
                    <div class="form-group my-4">
                        <button className="btn btn-info" onClick={ handleCargar }>
                            Cargar
                        </button>
                    </div>
                </form>
                </div>
            </div>
        </div>
    </>)
}