import  {useEffect, useState} from 'react'
import { BrowserRouter  as Router, Routes,Route,Link, useNavigate  } from 'react-router-dom'
import {url, url2} from '../Componentes/Const'
import { NivelesList } from '../Niveles/NivelesList'
import { NominasList } from '../Nominas/NominasList'
import { NominaDetalle } from '../Nominas/NominaDetalle'
import { PersonasList } from '../Personas/PersonasList'
import { PersonaAdd } from '../Personas/PersonaAdd'
import { NominaCreate } from '../Nominas/NominaCreate'
import { NominaEdit } from '../Nominas/NominaEdit'
import { ConceptosList } from '../Conceptos/ConceptosList'
import { ConceptosEdit } from '../Conceptos/ConceptoEdit'
import { ConceptoHistoricoEdit } from '../Conceptos/ConceptoHistoricoEdit'
import { ConceptoCreate } from '../Conceptos/ConceptoCreate'
import { CargosList } from '../Cargos/CargosList'
import { CargoCreate } from '../Cargos/CargoCreate'
import { CargoEdit } from '../Cargos/CargoEdit'
import { PersonaEdit } from '../Personas/PersonaEdit'
import { UsuariosList } from '../Usuarios/UsuariosList'
import { UsuarioEdit } from '../Usuarios/UsuarioEdit'
import { UsuarioAdd } from '../Usuarios/UsuarioAdd'
import { AuditoriasList } from '../Auditorias/AuditoriasList'
import { Principal } from './Principal'
import { EmpresaEdit } from'../Empresa/EmpresaEdit'
import { Informes } from '../Informes/Informes'
import {Appe} from '../Tests/Appe'

export function Tablero(){
  const navigate = useNavigate()
  const [usuario,setUsuario] = useState([])
  const [profile, setProfile] = useState([])
  useEffect(()=>{ 
    console.log("validando token")
    let user_id = 0
    try{
        const token = localStorage.getItem("token")
        console.log(token)

        //obteniedo dastos del usuario
        console.log("obteniendo datos")
        const options = {
            method : "POST",
            headers : {
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${token}`
            }
        }
        console.log(url2 +'/v1/auth/me')
        fetch(url2 +'/v1/auth/me',options)
        .then(response => response.json())
        .then(data => { console.log("obtuvo datos de usuario")
            //console.log(data)
            if(data){
                localStorage.setItem("profile",data.profile)
                localStorage.setItem("user_id",data.id)
                setProfile(localStorage.getItem("profile"))
                setUsuario(data.name)
                localStorage.setItem("usuario",data.name)
                user_id = localStorage.getItem("user_id")
                console.log("profile", localStorage.getItem("profile"))
            }else{
                console.log("no datos")
                navigate("/")
            }
        }).catch((error) => {
            console.log(error)
            navigate("/")
        })
            

        if(!token)
            navigate("/")
        console.log("profile", localStorage.getItem("profile"))
        console.log("salida Tablero")
    }catch(error){
        console.log("error")
      navigate("/")
    }
  },[])
    return <div className="row">
        <div className="col-2 bg-dark" >
        <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block  sidebar collapse">
            <center className='py-4'>
                <h4 className="text-white">{ usuario }</h4>
            </center>
            <ul className="nav nav-pills flex-column mb-auto">
                <li>Nomina -{profile}-
                    <ul>
                        <li className='nav-item'><Link to="/tablero/nominasList" class="nav-link text-white">Nominas Listado</Link></li>
                        {profile ==1 || profile == 2 ? (
                            <li className='nav-item'><Link to="/tablero/NominaCreate" class="nav-link text-white">Crear Nomina</Link></li>
                        ): (<></>)}
                        {profile ==1 || profile == 2 ? (
                            <li className='nav-item'><Link to="/tablero/informes" class="nav-link text-white">Informes</Link></li>
                        ): (<></>)}
                        
                    </ul>
                </li>
                
                <li>Otros
                    <ul>
                    {profile ==1 || profile == 2 ? (
                        <li><Link to="/tablero/empresa" class="nav-link text-white">Empresa</Link></li>
                    ): (<></>)}
                        <li><Link to="/tablero/personasList" class="nav-link text-white">Personal</Link></li>
                        {/* <li><Link to="/tablero/nivelesList" class="nav-link text-white">Niveles</Link></li> */}
                        <li><Link to="/tablero/conceptosList" class="nav-link text-white">Conceptos</Link></li>
                    {profile ==1 || profile == 2 ? (
                        <li><Link to="/tablero/cargosList" class="nav-link text-white">Cargos</Link></li>
                    ): (<></>)}
                        <li><Link to="/tablero/usuariosList" class="nav-link text-white">Usuarios</Link></li>
                    {profile ==1 || profile == 2 ? (
                        <li><Link to="/tablero/auditoriasList" class="nav-link text-white">Auditorias</Link></li>
                    ): (<></>)}
                        <li><Link to="/login" class="nav-link text-white"> Salir</Link></li>
                    </ul>
                </li>
                
            </ul>
            </nav>
        </div>
        <div className="col-10 ml-5 mt-4" >
          <Routes>
            <Route path="/" element={ <Principal/>}></Route>
            <Route path="/nivelesList" element={<NivelesList />}></Route>
            <Route path="/nominasList" element={<NominasList />}></Route>
            <Route path="/nominaDetalle/:id" element={<NominaDetalle />}></Route>
            <Route path="/nominaCreate/" element={<NominaCreate />}></Route>
            <Route path="/nominaEdit/:id" element={<NominaEdit />}></Route>
            <Route path="/personasList" element={<PersonasList />}></Route>
            <Route path="/personaEdit/:id" element={<PersonaEdit />}></Route>
            <Route path="/personaAdd" element={<PersonaAdd />}></Route>
            <Route path="/conceptosList" element={<ConceptosList />}></Route>
            <Route path="/conceptoEdit/:id" element={<ConceptosEdit />}></Route>
            <Route path="/conceptoHistoricoEdit/:id" element={<ConceptoHistoricoEdit />}></Route>
            <Route path="/conceptoCreate" element={<ConceptoCreate />}></Route>
            <Route path="/cargosList" element={<CargosList />}></Route>
            <Route path="/cargoCreate" element={<CargoCreate />}></Route>
            <Route path="/cargoEdit/:id" element={<CargoEdit />}></Route>
            <Route path="/usuariosList" element={<UsuariosList />}></Route>
            <Route path="/usuarioEdit/:id" element={<UsuarioEdit />}></Route>
            <Route path="/usuarioAdd" element={<UsuarioAdd />}></Route>
            <Route path="/auditoriasList" element={<AuditoriasList />}></Route>
            <Route path="/appe" element={<Appe />}></Route>
            <Route path="/empresa" element={<EmpresaEdit />}></Route>
            <Route path='/informes' element={<Informes/>}></Route>
        </Routes>
        </div>
    </div>

}