import React, {useState, useEffect,useRef} from 'react'
import {url,url2} from '../Componentes/Const'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
export function AuditoriasList(){
    const ref_texto = useRef()
    const [items, setItems] = useState([])
    
    useEffect(()=>{ 
        const token = localStorage.getItem("token")
        const requestOptions = {
            method: "GET",
            headers:{
                'Content-Type' : 'application/json',
                'Authorization' : 'Bearer '+token
            }
        }
        fetch(url +"/auditorias/AuditoriaBuscar.php",requestOptions)
        .then(response => response.json())
        .then(data => { console.log(data)
            setItems(data)
        })
        console.log("ejecutado")
    },[])

    function handleBuscar(){
        const token = localStorage.getItem("token")
        const requestOptions = {
            method: "GET",
            headers:{
                'Content-Type' : 'application/json',
                'Authorization' : 'Bearer '+token
            }
        }
        const texto = ref_texto.current.value
        fetch(url +"/auditorias/AuditoriaBuscar.php?texto="+texto, requestOptions)
        .then(response => response.json())
        .then(data => { console.log(data)
            setItems(data)
        })
        .catch(error=>{
            console.log(error, error)
            Swal("error obteniendo datos")
        } )

    }

    return <>
        <div class="text-center" >
            <h2>Auditorias</h2>
        </div>
        <div className="row" >
            <div className='col'>
                
            </div>
            <div className='col'>
                <div className="row" >
                    <div className='col'>
                        <input class="form-control form-control-lg" ref={ref_texto} placeholder='Buscar...'></input>
                    </div>
                    <div className='col'>
                        <button type="button" class="btn btn-primary btn-lg" onClick={handleBuscar}>B</button>
                    </div>
                </div>
            </div>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th>Fecha</th>
                    <th>Usuario</th>
                    <th>Evento</th>
                    {/* <th>Opciones</th> */}
                </tr>
            </thead>
            <tbody>
                {
                    items.map(dato=>(
                        <tr>
                            <td>{ dato.fechahora }</td>
                            <td>{ dato.name }</td>
                            <td>{ dato.accion }</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    </>

}