import { useState,useEffect } from "react"

export function Principal(){
    const [usuario, setUsuario] = useState([])
    useEffect(()=>{ 
        setUsuario(localStorage.getItem("usuario"))
     })
    return(<>
    <h1>Bienvenid@  { usuario }</h1>
    </>)
}