import React, {useState, useEffect} from 'react'
import {url2} from '../Componentes/Const'

import {Link} from 'react-router-dom'
export function NominasList(){
    const [items, setItems] = useState([])
    
    function cargar(){
        const token = localStorage.getItem('token')
        const requestOptions = {
            method: "GET",
            header :{
                "Content-Type" : "application/json",
                "Authorization" : "Bearer "+token
            }
        }
        fetch(url2 +"/nominas", requestOptions)
        .then(response => response.json())
        .then(data => { console.log(data)
            setItems(data)
        })
        .catch(error=> console.log(error))

    }
    useEffect(()=>{ 
        cargar()
    },[])
    

    return <>
        <div className='text-center'><h2>Listado de Nominas</h2></div> 
        {/* <div class="text-center" >
            <Link to="/tablero/categoriaCreate" className="btn btn-success" >Nuevo</Link>
        </div> */}
        <table class="table">
            <thead>
                <tr>
                    <th>Fecha</th>
                    <th>Descripción</th>
                    <th>Opciones</th>
                    <th>Estado</th>
                </tr>
            </thead>
            <tbody>
                {
                    items.map(dato=>(
                        <tr>
                            <td>{ dato.fecha_inicio } - {dato.fecha_fin}</td>
                            <td>{ dato.descripcion }</td>
                            <td><Link to={`/tablero/nominaEdit/${dato.id}`} className="btn btn-info">Editar</Link></td>
                            {dato.estado == 1 ? (
                                <td><Link to={`/tablero/nominaDetalle/${dato.id}`} className="btn btn-primary">Detalle</Link></td>
                            ) : (
                                  <td><Link to={`/tablero/nominaDetalle/${dato.id}`} className="btn btn-danger">Cerrada</Link></td>
                            )}
                            
                            
                        </tr>
                    ))
                }
            </tbody>
        </table>
    </>

}