import { useState,useEffect,useRef } from "react"
import { Link,useParams } from 'react-router-dom'
import { url } from "../Componentes/Const"
import {DatoInput} from "./DatoInput.js"
import { DatoLabel } from "./DatoLabel"
import { DatoLabelB } from "./DatoLabelB"
import {DatoLabelTotal} from "./DatoLabelTotal"
import html2canvas from 'html2canvas'
import Swal from "sweetalert2"
export function NominaDetalle(){
    const [cabeceras1, setCabeceras1] = useState([])
    const [items1, setItems1] = useState([])
    const [totales1, setTotales1] = useState([])
    const [valores1,setValores1] = useState([])

    const [cabeceras2, setCabeceras2] = useState([])
    const [items2, setItems2] = useState([])
    const [totales2, setTotales2] = useState([])
    const [valores2,setValores2] = useState([])

    const [cabeceras3, setCabeceras3] = useState([])
    const [items3, setItems3] = useState([])
    const [totales3, setTotales3] = useState([])
    const [valores3,setValores3] = useState([])

    const [estado, setEstado] = useState(false)
    const [loading, setLoading] = useState(true)
    const [descripcion, setDescripcion] = useState("Nomina")
    const [tabla1, setTabla1] = useState(false)
    const [tabla2, setTabla2] = useState(false)
    const [tabla3, setTabla3] = useState(false)
    const [obs, setObs] = useState(false)
    const [obsData, setObsData] = useState(false)
    const {id} = useParams()
    function cargar(nivel_id){ console.log("Cargado detallado de Nomina "+ nivel_id)
        const token = localStorage.getItem("token")
        if(nivel_id ==1){ 
            setTabla1(false) 
            setItems1([])
        }
        if(nivel_id ==2){ 
            setTabla2(false)
            setItems2([]) }
        setLoading(true)
        const requestOptions = {
            method: "GET",
            headers :{
                "Content-Type" : "application/json",
                'Authorization' : `Bearer ${token}`
            }
        }
        fetch(url +"/nomina/NominaDetalle.php?id="+id+"&nivel_id="+nivel_id, requestOptions)
        .then(response => response.json())
        .then(data => { console.log(data)
            if(nivel_id == 1){
                setCabeceras1(data.conceptos)
                setItems1(data.nomina)
                setTotales1(data.totales)
                setValores1(data.valores)
                //setTabla1(false)
                //setTabla2(false)
            }
            if(nivel_id == 2){
                setCabeceras2(data.conceptos)
                setTotales2(data.totales)
                setValores2(data.valores)
                setItems2(data.nomina)
                //setTabla2(false)
                //setTabla1(false)
            }
            
            setEstado(data.estado)
            setLoading(false)
            setObs(false)
            setObsData(data.observaciones)

        })
        .catch(error=> console.log(error))
    }

    function cargar3(nivel_id){ console.log("Cargado detallado de Nomina Nivel 3 "+ nivel_id)
        //if(nivel_id ==3){ setTabla3(false) }
        setItems3([])
        setLoading(true)
        const token = localStorage.getItem("token")
        const requestOptions = {
            method: "GET",
            headers :{
                'Content-Type' : "application/json",
                "Authorization" : "Bearer "+token
            }
        }
        fetch(url +"/nomina/NominaDetalle3.php?id="+id+"&nivel_id="+nivel_id, requestOptions)
        .then(response => response.json())
        .then(data => { console.log(data)
            if(nivel_id == 3){
                setCabeceras3(data.conceptos)
                setItems3(data.nomina)
                setTotales3(data.totales)
                setValores3(data.valores)
                setDescripcion(data.descripcion)
            }            
            setEstado(data.estado)
            setLoading(false)
        })
        .catch(error=> console.log(error))

    }
    useEffect(()=>{ 
        cargar(1)
        cargar(2)
        cargar3(3)
    },[])

    const handleRecargar1=()=>{
        cargar(1)
        setTabla1(true)
        setTabla2(false)
        setTabla3(false)
    }

    const handleRecargar2=()=>{
        cargar(2)
        setTabla2(true)
        setTabla1(false)
        setTabla3(false)
    }

    const handleRecargar3=()=>{
        cargar3(3)
        setTabla3(true)
        setTabla1(false)
        setTabla2(false)

    }

    const handleOcultarNivel1=()=>{
        if(tabla1){ console.log("ocultar")
            setTabla1(false)
        }else
            setTabla1(true)
    }

    const handleOcultarNivel2=()=>{
        if(tabla2){ console.log("ocultar")
            setTabla2(false)
        }else
            setTabla2(true)
    }

    const handleOcultarNivel3=()=>{
        if(tabla3){ console.log("ocultar")
            setTabla3(false)
        }else
            setTabla3(true)
    }

    const handleOcultarObs=()=>{
        if(obs){ console.log("ocultar")
            setObs(false)
        }else
            setObs(true)
    }
    const refObservaciones = useRef()
    const handleObservaciones=()=>{
        console.log("Editando")
        const user_id = localStorage.getItem("user_id")
        const item = {
            "id": id,
            "observaciones" : refObservaciones.current.value,
            "user_id" : user_id
        }
        console.log(item)
        const token = localStorage.getItem('token')
        const options = {
            method : "PUT",
            headers : {
                "Content-Type" : "application/json",
                'Authorization' : `Bearer ${token}`
            },
            body : JSON.stringify(item)
        }

        fetch(url + "/nomina/NominaObservaciones.php",options)
        .then(response => response.json())
        .then(data => { console.log(data)
            if(data.response == "ok"){
                Swal.fire(data.msj)
            }else
              Swal.fire(data.msj)
        })

    }

    const etiqueta1 = { position : "fixed",
        top: 55,
        right: "260px",
        zIndex : 2,
    }
    const etiquetaDestajo1 = { position : "fixed",
        top: 55,
        right: "360px",
        zIndex : 2,
    }

    const etiqueta2 = { position : "fixed",
        top: 100,
        right: "260px",
        zIndex : 2,
    }
    const etiquetaDestajo2 = { position : "fixed",
        top: 100,
        right: "360px",
        zIndex : 2,
    }

    const etiqueta3 = { position : "fixed",
        top: 145,
        right: "260px",
        zIndex : 2,
    }
    const etiquetaDestajo3 = { position : "fixed",
        top: 145,
        right: "360px",
        zIndex : 2,
    }

    const etiquetaAncho1 = {
        background : "white",
        width : "400px",
        position : "sticky",
        left: 0,
        fontSize : 12,
        // content: "Row"
        //text-nowrap
    }
    const etiquetaAncho2 = {
        background : "white",
        width : "400px",
        position : "sticky",
        left: 0,
        fontSize : 12,
        // content: "Row"
    }
    
    const etiquetaCabecera1 = {
        background : "white",
        position : "sticky",
        top : 80,
        boxShadow : "0 2px 2px -1px rgba(0,0,0,0,0.4)",
        fontSize : 10,
        zIndex : 1,
    }

    const etiquetaCabecera1H = {
        background : "white",
        position : "sticky",
        top : 45,
        boxShadow : "0 2px 2px -1px rgba(0,0,0,0,0.4)",
        fontSize : 10,
        zIndex : 1,
    }

    const etiquetaCabecera2 = {
        background : "white",
        position : "sticky",
        top : 45,
        boxShadow : "0 2px 2px -1px rgba(0,0,0,0,0.4)",
        fontSize : 10, 
    }

    const [isClicked1, setIsClicked1] = useState(true);
    const handleOcultar1 = ()=>{
        console.log("presionado")
        if(isClicked1)
            setIsClicked1(false);
        else
            setIsClicked1(true);
    }

    const [isClicked2, setIsClicked2] = useState(true);
    const handleOcultar2 = ()=>{
        console.log("presionado")
        if(isClicked2)
            setIsClicked2(false);
        else
            setIsClicked2(true);
    }

    const [isClicked3, setIsClicked3] = useState(true);
    const handleOcultar3 = ()=>{
        console.log("presionado")
        if(isClicked3)
            setIsClicked3(false);
        else
            setIsClicked3(true);
    }

    const exportRef1 = useRef();
    const exportRef2 = useRef();
    const exportRef3 = useRef();
    const exportAsImage = async (el, imageFileName) => {
        const canvas = await html2canvas(el);
        const image = canvas.toDataURL("image/png", 1.0);
        downloadImage(image, imageFileName);
    };
    const downloadImage = (blob, fileName) => {
        const fakeLink = window.document.createElement("a");
        fakeLink.style = "display:none;";
        fakeLink.download = fileName;
        
        fakeLink.href = blob;
        
        document.body.appendChild(fakeLink);
        fakeLink.click();
        document.body.removeChild(fakeLink);
        
        fakeLink.remove();
        };
    
    const handleEliminar = (persona_id,nomina_id,persona_nombre)=>{
        Swal.fire({
                title: 'Desea eliminar a '+ persona_nombre +' de esta Nomina?',
                showDenyButton: true,
                confirmButtonText: 'Si',
                denyButtonText: 'No',
                customClass: {
                  actions: 'my-actions',
                  cancelButton: 'order-1 right-gap',
                  confirmButton: 'order-2',
                  denyButton: 'order-3',
                }
              }).then((result) => {
                if (result.isConfirmed) {
                    eliminaEmpleado(persona_id,nomina_id,persona_nombre)
                  
                }
              })
    }

    function eliminaEmpleado(persona_id,nomina_id,persona_nombre){
        const user_id = localStorage.getItem("user_id")
        const item = {
            "persona_id" : persona_id,
            "nomina_id": nomina_id,
            "user_id" : user_id
        }
        console.log(item)
        const token = localStorage.getItem('token')
        const options = {
            method : "DELETE",
            headers : {
                "Content-Type" : "application/json"
                //,"x-auth-token" : token
            },
            body : JSON.stringify(item)
        }
        fetch(url + "/nomina/PersonaElimina.php",options)
        .then(response => response.json())
        .then(data => { console.log(data)
            if(data.response == "ok"){
              Swal.fire('Elimina Empleado!', data.msj, 'info')
            }else{
                Swal.fire('Elimina Empleado!', data.msj, 'danger')
            }
        })
    }
    return(<>
        <div className="row">
            <div className="col-12">
                <h3><b>{descripcion}</b></h3>
            </div>
        </div>
        <div className="row bd-example">
            <div className="col-4">
                <div className="row">
                    <div className="col">
                        <h3>Nivel 1</h3>
                    </div>
                    <div className="col">
                        <input type="button" className="btn btn-secondary btn-sm" onClick={handleOcultarNivel1} value="V"></input>
                        <button onClick={() => exportAsImage(exportRef1.current, "test")} className="btn btn-warning btn-sm">Img</button>
                        <a href={`${url}/nomina/NominaGeneralHoja.php?nomina_id=${id}&nivel_id=1`} target="_blank" className="btn btn-outline-success btn-sm" >XLS</a>
                    </div>
                </div>
                
            </div>
            <div className="col-1">
            { tabla1 ?(<>    
                <button onClick={handleRecargar1} className="btn btn-outline-warning btn-sm" style={etiqueta1} >Recargar</button>
                <button onClick={handleOcultar1} className="btn btn-outline-info btn-sm" style={etiquetaDestajo1} >Destajo</button>
                </>) : ( <></> ) }
            </div>
            <div className="col-1">
                <a href={`${url}/nomina/pdf/desprendibles/index.php?nomina_id=${id}`} target="_blank" className="btn btn-outline-danger btn-sm" >PDF</a>
            </div>
        </div>
        { loading ? (<>
        <div className="row text-center">
            <div className="col">
                <div class="spinner-border text-info" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
        </>) : (<div></div>) } 

        { tabla1 ? (   
        <div className="row">
            <table className="table table-striped table-hover" ref={exportRef1}>
                <thead style={etiquetaCabecera1}>
                    <tr>
                        <th className="bg-warning " style={etiquetaAncho1}>Trabajador &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        {
                        cabeceras1.map(dato=>(<>
                            { dato.categoria_id=="3" ? (
                             <th className={`table-${dato.color}`} style={{ display: isClicked1 ? 'none' : 'table-cell' }}>{dato.descripcion}</th>
                            ):(
                                <th className={`table-${dato.color}`}>{dato.descripcion}</th> 
                            )
                            }
                           
                           </>))}
                       {/* <th className="bg-info"></th> */}
                       <th className="bg-info"></th>
                    </tr>
                    <tr>
                        <th className="bg-info" style={etiquetaAncho1}>Valores</th>
                        { valores1.map(dato=>(<>
                            { dato.categoria_id=="3" ? (
                                <th className="table-info" style={{ display: isClicked1 ? 'none' : 'table-cell' }}>{dato.valor}</th>
                            ):(
                                <th className="table-info">{dato.valor}</th>
                            )
                            }</>
                        ))}
                       {/* <th className="bg-info"></th> */}
                       <th className="bg-info"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        items1.map(dato=>(
                            <tr>
                                <td style={etiquetaAncho1}>{ dato.nombre }</td>
                                { dato.conceptos.map(concepto=>(
                                    <>
                                    { concepto.categoria_id=="3" ? (
                                        <td style={{ display: isClicked1 ? 'none' : 'table-cell' }}>
                                        {concepto.editable=="1" && estado == "1" ? (
                                           <DatoInput valor={concepto.valor} info={concepto} ></DatoInput>
                                        ) : (
                                            <DatoLabel text={concepto.valor}></DatoLabel>
                                        )}
                                   </td>
                                        ) :
                                        (
                                            <td>
                                         {concepto.editable=="1" && estado == "1" ? (
                                            <DatoInput valor={concepto.valor} info={concepto}></DatoInput>
                                         ) : (
                                            concepto.concepto_des == 'Total Devengado' || concepto.concepto_des == 'total devengado 2' || concepto.concepto_des == 'devengado para soi' || concepto.concepto_des == 'Saldo a Pagar' ?
                                            (<DatoLabelB text={concepto.valor}></DatoLabelB>) 
                                            : (<DatoLabel text={concepto.valor}></DatoLabel>)
                                         )}
                                    </td>
                                        )
                                    } 
                                    
                                    </>
                                )) }
                                
                                <td>
                                    <a href={`${url}/nomina/NominaDesprendible.php?persona_id=${dato.persona_id}&nomina_id=${dato.nomina_id}&tipo=xls`} className="btn btn-outline-warning" target="_blank">Xls</a>
                                </td>
                                <td><a href={`${url}/nomina/NominaDesprendible.php?persona_id=${dato.persona_id}&nomina_id=${dato.nomina_id}`} className="btn btn-outline-info" target="_blank">V</a></td>
                                <td>
                                    <button class="btn btn-danger" onClick={ ()=> handleEliminar(dato.persona_id, dato.nomina_id,dato.nombre)}>X</button>
                                </td>
                            </tr>
                        ))
                    }
                    <tr>
                        <th className="table-success"></th>
                        {
                        totales1.map(dato=>(<>
                            {dato.categoria_id==3 ? (
                                <th className="table-success" style={{ display: isClicked1 ? 'none' : 'table-cell' }}><DatoLabelTotal text={dato}></DatoLabelTotal></th>
                            ):(
                                // if(dato.descripcion==8){
                                //     <th className="table-success"><DatoLabelTotal text={dato.valor}></DatoLabelTotal></th>
                                // }else{
                                    <th className="table-success"><DatoLabelTotal text={dato}></DatoLabelTotal></th>
                                // }
                            )}
                        </>
                        ))}
                       <th className="table-success"></th>
                    </tr>
                </tbody>
            </table>
        </div>
        ) : (<div></div>) } 

        <div className="row bd-example">
            <div className="col-4">
                <div className="row">
                    <div className="col">
                        <h3>Nivel 2</h3>
                    </div>
                    <div className="col">
                        <input type="button" className="btn btn-secondary btn-sm" onClick={handleOcultarNivel2} value="V"></input>
                        <button onClick={() => exportAsImage(exportRef2.current, "test")} className="btn btn-warning btn-sm">Img</button>
                        <a href={`${url}/nomina/NominaGeneralHoja.php?nomina_id=${id}&nivel_id=2`} target="_blank" className="btn btn-outline-success btn-sm" >XLS</a>
                    </div>
                </div>
                
            </div>
            <div className="col-1"> 
            { tabla2 ?(<>    
                <button onClick={handleRecargar2} className="btn btn-outline-warning btn-sm" style={etiqueta2}>Recargar</button>
                <button onClick={handleOcultar2} className="btn btn-outline-info btn-sm" style={etiquetaDestajo2}>Destajo</button>
            </>) : (<></>) }
            </div>
            <div className="col-1">
                {/* <a href="http://testnomina.ginga.com.co/Nomina/NominaExcel.php?id=2" target="_blank" className="btn btn-outline-success" >Descargar</a> */}
            </div>
        </div>

        { tabla2 ? (   
        <div className="row">
            <table className="table table-striped " ref={exportRef2}>
                <thead style={etiquetaCabecera2}>
                    <tr>
                        <th className="bg-warning " style={etiquetaAncho2}>Trabajador &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        {
                        cabeceras2.map(dato=>(<>
                            { dato.categoria_id=="3" ? (
                                <th className={`table-${dato.color}`} style={{ display: isClicked2 ? 'none' : 'table-cell' }}>{dato.descripcion}</th>
                            ):(
                                <th className={`table-${dato.color}`}>{dato.descripcion}</th> 
                            )
                            }
                           
                           </>))}
                       {/* <th className="bg-info"></th> */}
                       <th className="bg-info"></th>
                    </tr>
                    <tr>
                        <th className="bg-info" style={etiquetaAncho2}>Valores</th>
                        { valores2.map(dato=>(<>
                            { dato.categoria_id=="3" ? (
                                <th className="table-info" style={{ display: isClicked2 ? 'none' : 'table-cell' }}>{dato.valor}</th>
                            ):(
                                <th className="table-info">{dato.valor}</th>
                            )
                            }</>
                        ))}
                       {/* <th className="bg-info"></th> */}
                       <th className="bg-info"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        items2.map(dato=>(
                            <tr>
                                <td style={etiquetaAncho2}>{ dato.nombre }</td>
                                { dato.conceptos.map(concepto=>(
                                    <>
                                    { concepto.categoria_id=="3" ? (
                                        <td style={{ display: isClicked2 ? 'none' : 'table-cell' }}>
                                        {concepto.editable=="1" && estado == "1" ? (
                                           <DatoInput valor={concepto.valor} info={concepto} ></DatoInput>
                                        ) : (
                                            <DatoLabel text={concepto.valor}></DatoLabel>
                                        )}
                                   </td>
                                        ) :
                                        (
                                            <td>
                                         {concepto.editable=="1" && estado == "1" ?  (
                                            <DatoInput valor={concepto.valor} info={concepto} ></DatoInput>
                                         ) : (
                                            concepto.concepto_des == 'Total Devengado' || concepto.concepto_des == 'total devengado 2' || concepto.concepto_des == 'devengado para soi' || concepto.concepto_des == 'Saldo a Pagar' ?
                                            (<DatoLabelB text={concepto.valor}></DatoLabelB>) 
                                            : (<DatoLabel text={concepto.valor}></DatoLabel>)
                                         )}
                                    </td>
                                        )
                                    } 
                                    
                                    </>
                                )) }
                                <td>
                                    <a href={`${url}/nomina/NominaDesprendible.php?persona_id=${dato.persona_id}&nomina_id=${dato.nomina_id}&tipo=xls`} className="btn btn-outline-warning" target="_blank">Xls</a>
                                </td>
                                <td><a href={`${url}/nomina/NominaDesprendible.php?persona_id=${dato.persona_id}&nomina_id=${dato.nomina_id}`} className="btn btn-outline-info" target="_blank">V</a></td>
                                <td>
                                    <button class="btn btn-danger" onClick={ ()=> handleEliminar(dato.persona_id, dato.nomina_id,dato.nombre)}>X</button>
                                </td>
                            </tr>
                        ))
                    }
                    <tr>
                        <th className="table-success"></th>
                        {
                        totales2.map(dato=>(<>
                         { dato.categoria_id==3 ? (
                            <th className="table-success" style={{ display: isClicked2 ? 'none' : 'table-cell' }}><DatoLabelTotal text={dato}></DatoLabelTotal></th>
                         ):(
                            <th className="table-success"><DatoLabelTotal text={dato}></DatoLabelTotal></th>
                         )}
                        </>
                        ))}
                       <th className="table-success"></th>
                    </tr>
                </tbody>
            </table>
        </div>
        ) : (<div></div>) } 


    <div className="row bd-example">
            <div className="col-4">
                <div className="row">
                    <div className="col">
                        <h3>Nivel 3</h3>
                    </div>
                    <div className="col">
                        <input type="button" className="btn btn-secondary btn-sm" onClick={handleOcultarNivel3} value="V"></input>
                        <a href={`${url}/nomina/NominaGeneralHoja.php?nomina_id=${id}&nivel_id=3`} target="_blank" className="btn btn-outline-success btn-sm" >XLS</a>
                    </div>
                </div>
                
            </div>
            <div className="col-1"> 
            { tabla3 ?(<>    
                <button onClick={handleRecargar3} className="btn btn-outline-warning btn-sm" style={etiqueta3}>Recargar</button>
                <button onClick={handleOcultar3} className="btn btn-outline-info btn-sm" style={etiquetaDestajo3}>Destajo 3</button>
            </>) : (<></>) }
            </div>
        </div>

        { tabla3 ? (   
       
       <div className="row">
            <table className="table table-striped ">
                <thead style={etiquetaCabecera2}>
                    <tr>
                        <th className="bg-warning " style={etiquetaAncho2}>Trabajador &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        {
                        cabeceras3.map(dato=>(<>
                            { dato.categoria_id=="3" ? (
                                <th className={`table-${dato.color}`} style={{ display: isClicked3 ? 'none' : 'table-cell' }}>{dato.descripcion}</th>
                            ):(
                                <th className={`table-${dato.color}`}>{dato.descripcion}</th> 
                            )
                            }
                           
                           </>))}
                       {/* <th className="bg-info"></th> */}
                       <th className="bg-info"></th>
                    </tr>
                    <tr>
                        <th className="bg-info" style={etiquetaAncho2}>Valores</th>
                        { valores3.map(dato=>(<>
                            { dato.categoria_id=="3" ? (
                                <th className="table-info" style={{ display: isClicked3 ? 'none' : 'table-cell' }}>{dato.valor}</th>
                            ):(
                                <th className="table-info">{dato.valor}</th>
                            )
                            }</>
                        ))}
                       {/* <th className="bg-info"></th> */}
                       <th className="bg-info"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        items3.map(dato=>(
                            <tr>
                                <td style={etiquetaAncho2}>{ dato.nombre }</td>
                                { dato.conceptos.map(concepto=>(
                                    <>
                                    { concepto.categoria_id=="3" ? (
                                        <td style={{ display: isClicked3 ? 'none' : 'table-cell' }}>
                                        {concepto.editable=="1" && estado == "1" ? (
                                           <DatoInput valor={concepto.valor} info={concepto} ></DatoInput>
                                        ) : (
                                            <DatoLabel text={concepto.valor}></DatoLabel>
                                        )}
                                   </td>
                                        ) :
                                        (
                                            <td>
                                         {concepto.editable=="1" && estado == "1" ?  (
                                            <DatoInput valor={concepto.valor} info={concepto} ></DatoInput>
                                         ) : (
                                            concepto.concepto_des == 'Total Devengado' || concepto.concepto_des == 'total devengado 2' || concepto.concepto_des == 'devengado para soi' || concepto.concepto_des == 'Saldo a Pagar' ?
                                            (<DatoLabelB text={concepto.valor}></DatoLabelB>) 
                                            : (<DatoLabel text={concepto.valor}></DatoLabel>)
                                         )}
                                    </td>
                                        )
                                    } 
                                   
                                    </>
                                )) }
                                <td>
                                    <a href={`${url}/nomina/NominaDesprendible.php?persona_id=${dato.persona_id}&nomina_id=${dato.nomina_id}`} className="btn btn-outline-warning" target="_blank">Xls</a>
                                </td>
                                <td>
                                    <button class="btn btn-danger" onClick={ ()=> handleEliminar(dato.persona_id, dato.nomina_id,dato.nombre)}>X</button>
                                </td>
                            </tr>
                        ))
                    }
                    <tr>
                        <th className="table-success"></th>
                        {
                        totales3.map(dato=>(<>
                         { dato.categoria_id==3 ? (
                            <th className="table-success" style={{ display: isClicked3 ? 'none' : 'table-cell' }}>&nbsp;</th>
                         ):(
                            <th className="table-success"><DatoLabelTotal text={dato}></DatoLabelTotal></th>
                         )}
                        </>
                        ))}
                       <th className="table-success"></th>
                    </tr>
                </tbody>
            </table>
        </div>
        ) : (<div></div>) } 
        
        <div className="row bd-example">
            <div className="row">
                <div className="col">
                    <h3>Observaciones</h3>
                </div>
                <div className="col">
                    <input type="button" className="btn btn-secondary btn-sm" onClick={handleOcultarObs} value="V"></input>
                </div>
            </div>
            
            { obs ? (<div className="row">
                <div className="col">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Observaciones</h5>
                        <textarea className="form-control" ref={refObservaciones} rows="10">{ obsData }</textarea>
                        { estado ? (<input type="button" className="btn btn-warning btn-sm" onClick={handleObservaciones} value="Actualizar"></input>)
                        : (<div></div>) }
                       
                    </div>
                    
                </div>
                </div>
            </div>
            ) : (<div></div>) }
        </div>
</>)
}