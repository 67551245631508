import { useEffect,useRef,useState } from "react"
import { useParams } from "react-router-dom"
import {url,url2} from '../Componentes/Const'
import swal from 'sweetalert2'
export function UsuarioAdd(){
    const ref_nombre = useRef()
    const ref_correo = useRef()
    const ref_contrasena = useRef()
    const ref_estado = useRef()
  
    const [us_estado, setUs_estado] = useState([])

    const handleSubmit =()=>{
        console.log("Guardando")
        const item = {
            "nombre" : ref_nombre.current.value,
            "contrasena" : ref_contrasena.current.value,
            "correo" : ref_correo.current.value,
        }
        console.log(item)
        const token = localStorage.getItem('token')
        const options = {
            method : "POST",
            headers : {
                "Content-Type" : "application/json",
            //    "x-auth-token" : token
            },
            body : JSON.stringify(item)
        }

        fetch(url + "/usuarios/UsuarioAdd.php",options)
        .then(response => response.json())
        .then(data => { console.log(data)
            if(data.response == "ok")
                swal.fire(data.msj)
            else
                swal.fire(data.msj)
        })
    }

    return <>
        <h2>Crear un Usuario</h2>
        <form>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Nombre
                </label>
                <input type="text" className="form-control" ref={ ref_nombre } placeholder="Nombre" />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Correo
                </label>
                <input type="text" className="form-control" ref={ ref_correo } placeholder="Correo Electronico"/>
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Contraseña
                </label>
                <input type="password" className="form-control" ref={ ref_contrasena } placeholder="Contraseña"/>
            </div>
            <div class="form-group my-4">
                <button type="button" className="btn btn-primary" onClick={ handleSubmit }>
                    Guardar
                </button>
			</div>

        </form>
    </>
}