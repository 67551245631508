import React, { useEffect, useState } from 'react';

export function DatoLabelTotal(props) { console.log("!",props)
  const [textF, setTextF] = useState(props.valor);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })

  useEffect(()=>{ 
    if(props.text){
      let peso
      if(props.text.valor)
          peso= parseFloat(props.text.valor)
      else
        peso= parseFloat(props.text) 

      if(isNaN(peso))
        peso = "0"
      else
        if(peso > 599){
          peso = formatter.format(peso)
        }else{
          peso = Math.round(peso)
        }
      setTextF(peso)
    }
  },[])

    return (
    <div>
        <label>{textF}</label>
    </div>
  );
}

export default DatoLabelTotal;