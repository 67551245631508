import React, {useState, useEffect, useRef} from 'react'
import {url,url2} from '../Componentes/Const'

import {Link} from 'react-router-dom'
import swal from 'sweetalert2'
export function ConceptosList(){
    const ref_texto = useRef()
    const ref_nomina = useRef()
    const [titulo, setTitulo] = useState([])
    const [items, setItems] = useState([])
    const [historico, setHistorico] = useState(false)
    const [nominas,setNominas] = useState([])
    function cargar(){
        const token = localStorage.getItem("token")
        const requestOptions = {
            method: "GET",
            headers :{
                'Content-Type' : 'application/json'
            }
        }
        fetch(url2 +"/conceptos", requestOptions)
        .then(response => response.json())
        .then(data => { console.log(data)
            setItems(data)
        })
        .catch(error=>{
            console.log(error)
            alert("error obteniendo datos")
        } )
        const requestO = {
            method: "GET",
            headers:{
                'Content-Type' : 'application/json',
                'Authorization' : 'Bearer '+token
            }
        }
        fetch(url +"/nomina/NominaListado.php", requestO)
        .then(response => response.json())
        .then(data => { console.log(data)
            setNominas(data)
        })
        .catch(error=>{
            console.log("error listado",error.error)
            swal.fire(error.error)
           // alert("error obteniendo datos")
        })
    }
    useEffect(()=>{ 
        cargar()
        setTitulo("Actual")
    },[])
    
    function handleConceptoBuscar(){
        const token = localStorage.getItem("token")
        const requestOptions = {
            method: "GET",
            headers :{
                "Content-Type" : "application/json",
                'Authorization' : 'Bearer '+token
            }
        }
        const texto = ref_texto.current.value
        const urlI = url +"/concepto/ConceptoBuscar.php?texto="+texto
        console.log(urlI)
        fetch(urlI, requestOptions)
        .then(response => response.json())
        .then(data => { console.log(data)
            setItems(data)
            
        })
        .catch(error=>{
            console.log(error)
            //alert("error obteniendo datos")
            swal.fire("Error obteniendo datos")
        } )

    }

    function handleNominaConceptos(event){
        console.log("buscando nominas")
        const token = localStorage.getItem("token")
        const requestOptions = {
            method: "GET",
            headers :{
                "Content-Type" : "application/json",
                'Authorization' : 'Bearer '+token
            }
        }
        console.log(url +"/concepto/ConceptoListado.php?nomina_id="+ref_nomina.current.value)
        fetch(url +"/concepto/ConceptoListado.php?nomina_id="+ref_nomina.current.value , requestOptions)
        .then(response => response.json())
        .then(data => { console.log(data)
            setItems(data)
            setHistorico(true)
            setTitulo(ref_nomina.current.options[ref_nomina.current.selectedIndex].text)
        })
        .catch(error=>{
            console.log(error)
            alert("error obteniendo datos")
        } )
    }

    return <>
        <div className="row" >
            <div className='col-lg-3'>
                <Link to="/tablero/conceptoCreate" className="btn btn-success" >Nuevo Concepto</Link>
            </div>
            <div className='col-lg-9'>
                <div className="row" >
                    <div className='col-lg-3'>
                        <input class="form-control form-control-lg" ref={ref_texto} placeholder='Buscar...'></input>
                    </div>
                    <div className='col-lg-1'>
                        <button type="button" class="btn btn-primary btn-lg" onClick={handleConceptoBuscar}>B</button>
                    </div>
                    <div className='col-lg-8'>
                        <select className='form-select' ref={ref_nomina} onChange={handleNominaConceptos} >
                            <option>Historicos...</option>
                            {   nominas.map(dato=>(
                                <option value={dato.id}>
                                    {dato.descripcion}
                                </option>
                            ))
                            }
                        </select>
                    </div>
                </div>
            </div>
            
        </div>
        <div className='row'>
            <div className='col'>
                <h3>{ titulo}</h3>
            </div>
        </div>
        <div className='row'>
            <table class="table">
                <thead>
                    <tr>
                        <th>Concepto</th>
                        <th>Nivel</th>
                        <th>Precio</th>
                        <th>Opciones</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        items.map(dato=>(
                            <tr>
                                <td>{ dato.descripcion }</td>
                                <td>{ dato.nivel_id }</td>
                                <td>{ dato.precio }</td>
                                <td>{ historico ? 
                                    (<Link to={`/tablero/conceptoHistoricoEdit/${dato.id}`} className="btn btn-secondary">Editar</Link>)
                                    : 
                                    (<Link to={`/tablero/conceptoEdit/${dato.id}`} className="btn btn-secondary">Editar</Link>)
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
        
    </>

}