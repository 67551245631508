import { useEffect,useRef,useState } from "react"
import { useParams } from "react-router-dom"
import {url} from "../Componentes/Const"
import Swal from 'sweetalert2'
export function NominaEdit(){
    const ref_fechaInicio = useRef()
    const ref_fechaFin = useRef()
    const ref_fecha = useRef()
    const ref_descripcion = useRef()
    const ref_observaciones = useRef()
    const ref_estado = useRef()
    const {id} = useParams()
    const [items,setItems] = useState([])
    const [empleados,setEmpleados] = useState([])

    const [us_estado, setUs_estado] = useState([])
    const [us_disabled, setUs_disabled] = useState(false)
    console.log(id)
    useEffect(()=>{
        const token = localStorage.getItem("token")
        const requestOptions = {
            method: "GET",
            headers :{
                'Content-Type' : "application/json",
                "Authorization" : "Bearer "+token
            }
        }
        const urlI =url + "/metodos/get.php?tabla=nominas&id=" + id
        console.log(urlI)
        fetch(urlI,requestOptions)
        .then(response => response.json())
        .then(data =>{ console.log(data)
            ref_fechaInicio.current.value = data.fecha_inicio
            ref_fechaFin.current.value = data.fecha_fin
            ref_fecha.current.value = data.fecha
            ref_descripcion.current.value = data.descripcion
            ref_observaciones.current.value = data.observaciones
            setUs_estado(data.estado)
        })

        const profile = localStorage.getItem("profile")
        if(profile != 1 && profile !=2)
                setUs_disabled(true)
        
        //obtener los empleados no registradoa la nomina
        const url2 = url + "/nomina/PersonaLibre.php?nomina_id=" + id
        console.log(url2)
        fetch(url2,requestOptions)
        .then(response => response.json())
        .then(data =>{ console.log(data)
            setEmpleados(data)
        })
    },[])

    const handleSubmit =()=>{
        console.log("Editando")
        const item = {
            "id" : id,
            "descripcion" : ref_descripcion.current.value,
            "observaciones": ref_observaciones.current.value,
            "estado" : ref_estado.current.value
        }
        
        const token = localStorage.getItem('token')
        const options = {
            method : "PUT",
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : "Bearer "+token
            },
            body : JSON.stringify(item)
        }
        console.log(item)
        fetch(url + "/nomina/NominaUpdate.php",options)
        .then(response => response.json())
        .then(data => { console.log(data)
            if(data.response == "ok")
                alert(data.msj)
               // swal.fire("Cliente Actualizad@ con Exito")
            else
                alert(data.msj)
                // swal.fire(data.msj)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const handleEstado = ()=>{
        if(ref_estado.current.value == 1){
            if(window.confirm("Esta seguro de Activar la Nomina?") == true){
                alert("Nomina Abierta")
                setUs_estado(ref_estado.current.value)
            }else{
                setUs_estado(0)
            }
        }else{
            setUs_estado(ref_estado.current.value)
        } 
    }
    
    const [us_empleado,setUs_empleado] = useState([])
    const handleEmpleado = ()=>{
        setUs_estado(ref_empleado.current.value)
    }

    function findObjectById(arr, id) {
        // Use the `find` method to search for an object with a matching `id` property
        return arr.find(obj => obj.id === id);
      }
    const ref_empleado = useRef()
    const handleAgregar = (ev)=>{
        ev.preventDefault()
        const objectId = ref_empleado.current.value
        const empleado = findObjectById(empleados, objectId)
        Swal.fire({ 
                title: 'Desea agregar a '+ empleado.nombre +' - Nivel '+ empleado.nivel_id +', a esta Nomina?',
                showDenyButton: true,
                confirmButtonText: 'Si',
                denyButtonText: 'No',
                customClass: {
                  actions: 'my-actions',
                  cancelButton: 'order-1 right-gap',
                  confirmButton: 'order-2',
                  denyButton: 'order-3',
                }
              }).then((result) => {
                if (result.isConfirmed) {
                    agregaEmpleado(objectId,empleado.nivel_id)
                  
                }
              })
    }
    function agregaEmpleado (persona_id,nivel_id){
        console.log("Editando")
        const user_id = localStorage.getItem("user_id")
        const item = {
            "nomina_id" : id,
            "persona_id" : persona_id,
            "nivel_id": nivel_id,
            "user_id" : user_id 
        }
        
        const token = localStorage.getItem('token')
        const options = {
            method : "POST",
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : "Bearer "+token
            },
            body : JSON.stringify(item)
        }
        console.log(item)
        fetch(url + "/nomina/PersonaAgregar.php",options)
        .then(response => response.json())
        .then(data => { console.log(data)
            if(data.response == "ok")
                Swal.fire({
                    title :"Agregar a Nomina",
                    text : data.msj,
                    icon : "success"})
            else
                Swal.fire({title :"Agregar a Nomina",
                text : data.msj,
                icon : "danger"})
                
        })
        .catch((error) => {
            console.log(error)
        })
    }
   
    return <>
        <h2>Editar Nomina</h2>
        <form>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Fecha Inicio
                </label>
                <input type="date" className="form-control" ref={ ref_fechaInicio } disabled/>
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Fecha Fin
                </label>
                <input type="date" className="form-control" ref={ ref_fechaFin } disabled/>
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Fecha
                </label>
                <input type="date" className="form-control" ref={ ref_fecha } disabled/>
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Descripcion
                </label>
                <input type="text" className="form-control" ref={ ref_descripcion } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Observaciones
                </label>
                <input type="text" className="form-control" ref={ ref_observaciones } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Estado
                </label>
                <select className="form-select" ref={ref_estado} value={us_estado} onChange={handleEstado} disabled={us_disabled}>
                        <option value='1' >Abierta</option>
                        <option value='0' >Cerrada</option>
                </select>
            </div>
            <div class="form-group my-4">
                <button type="button" className="btn btn-primary" onClick={ handleSubmit }>
                    Actualizar
                </button>
			</div>
            <div class="form-group my-4">
                <a href={`/tablero/nominaDetalle/${id}`} className="btn btn-warning" onClick={ handleSubmit }>
                    Detalle
                </a>
			</div>

        </form>
        <form>
            
            <h2>Empleados No Vinculados</h2>
            <div class="form-group"> 
            <select className="form-select" ref={ref_empleado} onChange={handleEmpleado}>
                <option value="0" >Seleccione</option>
                {
                    empleados.map(dato=>(
                        <option value={dato.id} >{dato.nombre} - Nv. {dato.nivel_id}</option>
                    ))
                }        
            </select>
            </div>
            <div class="form-group my-4">
                <button className="btn btn-info" onClick={ handleAgregar }>
                    Agregar
                </button>
			</div>
        </form>
    </>
}