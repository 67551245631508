import React from "react";
import Table from "./Table";

const data = [
  { name: "John", age: 25, city: "New York" },
  { name: "Jane", age: 30, city: "Chicago" },
  { name: "Bob", age: 35, city: "San Francisco" },
];

export function Appe() {
  return <Table data={data} />;
}
