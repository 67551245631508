import { useEffect,useRef,useState } from "react"
import { useParams } from "react-router-dom"
import {Link} from 'react-router-dom'
import {url} from '../Componentes/Const'
import swal from 'sweetalert2'
export function CargoEdit(){
    const ref_descripcion = useRef()
    const {id} = useParams()
    const ref_estado = useRef()
    const [us_estado, setUs_estado] = useState([])

    useEffect(()=>{
        const token = localStorage.getItem('token') 
        const options = {
            method : "GET",
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : "Bearer "+token
            }
        }
        console.log(url +"/metodos/get.php?tabla=cargos&id=" + id)
        fetch(url +"/metodos/get.php?tabla=cargos&id=" + id,options)
        .then(response => response.json())
        .then(data =>{ console.log(data)
            ref_descripcion.current.value = data.descripcion
            setUs_estado(data.estado)
        })
    },[])

    const handleSubmit =()=>{
        let errores = false

        if(ref_descripcion.current.value.length < 4){
            errores = true
            swal.fire("Debes llenar el dato Descripcion")
        }
        
        if(!errores){
            console.log("Guardando")
            const item = {
                "descripcion": ref_descripcion.current.value,
                "id": id,
                "estado" : ref_estado.current.value 
            }
            console.log(item)
            const token = localStorage.getItem('token')
            const options = {
                method : "PUT",
                headers : {
                    "Content-Type" : "application/json",
                    "Authorization" : "Bearer "+token
                },
                body : JSON.stringify(item)
            }

            fetch(url + "/cargos/CargoUpdate.php",options)
            .then(response => response.json())
            .then(data => { console.log(data)
                if(data.response == "ok")
                    swal.fire(data.msj)
                else
                    swal.fire(data.msj)
            })
            
        } 
    }
    const handleEstado = ()=>{
        setUs_estado(ref_estado.current.value)
    }
    return <>
        <h2>Crear Cargo</h2>
        <form>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Descripcion
                </label>
                <input type="text" className="form-control" ref={ ref_descripcion } />
            </div>

            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Estado
                </label>
                <select className="form-select" ref={ref_estado} value={us_estado} onChange={handleEstado}>
                        <option value='1' >Activo</option>
                        <option value='0' >Inactivo</option>
                </select>
            </div>
            <div class="form-group my-4">
                <button type="button" className="btn btn-primary" onClick={ handleSubmit }>
                    Actualizar
                </button>
			</div>
        </form>
    </>
}