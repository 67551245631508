import React, {useState, useEffect} from 'react'
import {url,url2} from '../Componentes/Const'

import {Link} from 'react-router-dom'
export function UsuariosList(){
    const [items, setItems] = useState([])
    const user_id = localStorage.getItem('user_id')
    const profile = localStorage.getItem('profile')
    function cargar(){
        const requestOptions = {
            method: "GET",
            header :{
                "Content-Type" : "application/json"
            }
        }
        let URI = ""
        if(profile == "1"){
            URI = url2 +"/usuarios"
            console.log(URI)
            fetch(URI, requestOptions)
            .then(response => response.json())
            .then(data => { console.log(data)
                setItems(data)
            })
            .catch(error=> console.log(error))
        }else{
            URI = url + "/metodos/get.php?tabla=users&id=" + user_id
            console.log(URI)
            fetch(URI, requestOptions)
            .then(response => response.json())
            .then(data => { console.log(data)
                let dato = []
                dato.push(data)
                setItems(dato)
            })
            .catch(error=> console.log(error))
        }
        

    }
    useEffect(()=>{ 
        cargar()
    },[])
    

    return <>
        <div class="text-center" >
            <Link to="/tablero/usuarioAdd" className="btn btn-success" >Nuevo</Link>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th>Correo</th>
                    <th>Opciones</th>
                </tr>
            </thead>
            <tbody>
                {
                    items.map(dato=>(
                        <tr>
                            <td>{ dato.name }</td>
                            <td><Link to={`/tablero/usuarioEdit/${dato.id}`} className="btn btn-secondary">Editar</Link></td>
                        </tr>                      
                    ))
                }
            </tbody>
        </table>
    </>

}